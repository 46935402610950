import React, {
    useEffect,
    Fragment,
    useCallback,
    useState,
    useRef,
  } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Header from '../components/Header'
import { Box, Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Select from "react-dropdown-select"
import {BiArrowBack} from "react-icons/bi"
import {BiChevronUp, BiChevronDown} from "react-icons/bi"
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Slide from '@material-ui/core/Slide'
import { Pagination } from '@material-ui/lab';
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Setting from '../components/Setting'
import Typography from '@material-ui/core/Typography'
import { BiXCircle,BiInfoCircle } from "react-icons/bi"
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { Link } from 'react-router-dom'
import { apiAxios } from "../Util/ApiAxios"
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PatientStatus from '../components/PatientStatus'
import AddPatientProgramModal from "./AddPatientProgramModal";
import EditPatientProgramModal from './EditPatientProgramModal'
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
function PatientSearch(props, { options }) {
    const classes = useStyles();
    
  const [selectedValue, setSelectedValue] = React.useState('patient');
  const [providerList, setProviderList] = useState([]);
  const [providerListOption, setProviderListOption] = useState([]); 
  const [selectedProvider, setSelectedProvider] = useState("");

  const [programList, setProgramList] = useState([]);
  const [programListOption, setProgramListOption] = useState([]); 
  const [selectedProgram, setSelectedProgram] = useState("");
  const [programUniqueList, setProgramUniqueList] = useState([]);
  const [programUListOption, setProgramUListOption] = useState([]);
  const [patientList, setPatientList] = useState([]);

  const [searchVal, setSearchVal] = useState("");
  const [checked, setChecked] = React.useState(false);

  const [message, setMessage] = useState("");

  const [flag, setFlag] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const currentPageRef = useRef(1);
  const [currentReportPage, setCurrentReportPage] = useState(1);
  const [totalReportPages, setTotalReportPages] = useState(1);

  const [patientID, setPatientID]= React.useState("");
  const [patientReport, setPatientReport] = React.useState("")
  const [tablemessage, setTableMessage] = useState("");

  let domain_name= "https://dhct-new-dev-media.s3.us-east-1.amazonaws.com/"

  useEffect(() => {
    getProviderList();
    getProgramList();
    getPatientList();
    getProgramUniqueList();

    if(localStorage.getItem("tab")){
      localStorage.removeItem("tab");
      localStorage.removeItem("panel");
    }

    if(localStorage.getItem("engagement_timer")){
      localStorage.removeItem("engagement_timer");
    }

  }, []);  

  useEffect(() => {
    if(providerList){ 
       var ob_arr =[];
       //console.log("user types = ", userTypes);
       for(var i=0;i<providerList.length;i++){
       let value = providerList[i].id;
       let label = providerList[i].first_name + " " + providerList[i].last_name;
       let ob = {
           "label":label, "value": value
       }
       //console.log("ob types = ", ob);
       ob_arr.push(ob);
       }
       setProviderListOption(ob_arr);
       setSelectedProvider(ob_arr[0]);
   }
   
 }, [providerList]);  

 const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };


 useEffect(() => {
    if(programList){ 
       var ob_arr =[];
       //console.log("user types = ", userTypes);
       for(var i=0;i<programList.length;i++){
       let value = programList[i].id;
       let label = programList[i].program_name+ "-" + programList[i].first_name + " " + programList[i].last_name;
       let id = programList[i].template_id;
       let ob = {
           "label":label, "value": value, template_id: id
       }
      //  console.log("ob types = ", ob);
       ob_arr.push(ob);
       }
       setProgramListOption(ob_arr);
       setSelectedProgram(ob_arr[0]);
   }
   
 }, [programList]);  


 useEffect(()=>{
  if(programUniqueList){
    let ob_arr = []
    for (var i=0; i< programUniqueList.length; i++){
      let value = programUniqueList[i].id;
      let label = programUniqueList[i].program_name;
      let id = programUniqueList[i].template_id
      let ob = {
        label : label,
        value : value,
        template_id : id

      }

      ob_arr.push(ob)


    }

    setProgramUListOption(ob_arr)

  }

 },[programUniqueList])

  function openPatProgram(data) {

    console.log(data);

    props.history.push({
        pathname: "/patient-information",
        state: {
          patientInfo: data,
          providerList: providerListOption,
          // programList: programListOption
          programList: programUListOption
        },
      });
  } 


  function getProviderList() {

    let jwt = JSON.parse(localStorage.getItem("JWT"));

    let data ={
      table_config_rows_per_page: '25',
      table_config_page_no: '1',
      search_value: "",
      cutid: "",
      user_master_type_id: "",
      client_id: jwt.client_id,
      is_provider: 1
    }

    apiAxios
        .post("/client/clientuserlist", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setProviderList(response.data.data); 
              
          }
          else {
             
          }
        })
        .catch((error) => {
          console.log(error);
  
         

              localStorage.removeItem("Refresh_Token");
              localStorage.removeItem("Token");
              window.location.href = "/";        

  
        });

        
  }

  // delete patient
  const deletePatient = (id) =>{

    let data ={
      
      patient_program_id: id,
    }

    apiAxios
        .post("/program/delete-client-patient-programs", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("delete response --> ",response);
          if(response.data.status == 200) {
             toast.success(response.data.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            const timer = setTimeout(()=>{
              window.location.href=("/patient-search")
            },2000)
           
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
          }
        })
        .catch((error) => {
          console.log(error);
  
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              localStorage.removeItem("Refresh_Token");
              localStorage.removeItem("Token");
              window.location.href = "/";        

  
        });
      


  }

  // delete modal

  const deleteModal = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to delete this record?</p>
            <button className={classes.modalNoBtn} style={{border: '2px solid #0f3d6e',  cursor: 'pointer', width:'auto'}} onClick={onClose}>No</button>
            <button className={classes.loginbtn} style={{marginLeft: '10px', cursor: 'pointer', width:'auto'}}
              onClick={() => {
                deletePatient(id);
                onClose();
              }}
            >
              Yes, Delete
            </button>
          </div>
        );
      }
    });
  };



  // download finalize pdf
  const downloadPDF = (id) => {


    let data ={
      pdf_type: 0,
      finalization_id: id,
    }

    apiAxios
        .post("/report/create-patient-report", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("fin response --> ",response);
          if(response.data.statusCode == 200) {
              
            let url = response.data.domain_name+response.data.s3_finalization_pdf_report_url;
            console.log(url)
            window.location.href = url;
              
          }
          else {
              toast.error(response.data.message, {
                  position: "bottom-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
          }
        })
        .catch((error) => {
          console.log(error);
  
          toast.error(error.message, {
              position: "bottom-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });

              localStorage.removeItem("Refresh_Token");
              localStorage.removeItem("Token");
              window.location.href = "/";        

  
        });
  }

  function getProgramList() {


    let data ={
        table_config_rows_per_page: "50",
        table_config_page_no: "1",
        program_id: "",
        provider_id: "",
        client_id: "",
        search_value: "",
        status: "1"
      }

    apiAxios
        .post("/client/client-provider-program-list", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setProgramList(response.data.data); 
              
          }
          else {
             
          }
        })
        .catch((error) => {
          console.log(error);
  
          

              localStorage.removeItem("Refresh_Token");
              localStorage.removeItem("Token");
              window.location.href = "/";        

  
        });
  }


  // program unique list

  function getProgramUniqueList() {


    let data ={
        table_config_rows_per_page: "100",
        table_config_page_no: "1",
        program_id: "",
        provider_id: "",
        client_id: "",
        search_value: "",
        status: "1",
        is_unique : "1"
      }

    apiAxios
        .post("/client/client-provider-program-list", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setProgramUniqueList(response.data.data); 
              
          }
          else {
             
          }
        })
        .catch((error) => {
          console.log(error);
  
         

              // localStorage.removeItem("Refresh_Token");
              // localStorage.removeItem("Token");
              // window.location.href = "/";        

  
        });
  }


  function submitSearch() {
      getPatientList();
      setFlag(true);
  }

  function resetFilter() {
    window.location.href = "/patient-search";
}

  const getPatientList = useCallback(
    async ({
      table_config_rows_per_page = '15',
      table_config_page_no = '1',
    } = {}) => {

    setCurrentPage(parseInt(table_config_page_no));
        
    setPatientList([]);
    setMessage("Loading...");

    

    let data ={
        table_config_page_no: table_config_page_no,
        pd_id: "",
        client_user_id: selectedProvider[0] ? selectedProvider[0].value : "",
        search_value: searchVal? searchVal : "",
        status: "",
        assigned_to_me: checked ? "1" : "0",
        client_provider_program_id: selectedProgram[0] ? selectedProgram[0].value : "",
        table_config_rows_per_page: table_config_rows_per_page,
        provider_only: true
      }

    apiAxios
        .post("/patient/client-patient-program-list", data, {
          headers: {
              "Content-Type": "application/json",
              "Authorization": localStorage.getItem("Token")
          },
        })
        .then((response) => {
          console.log("response --> ",response);
          if(response.data.statusCode == 200) {
              setPatientList(response.data.data); 
              setMessage("");
              setTotalPages(response.data.total_pages);
              
          }
          else if(response.data.statusCode == 502){
              setMessage("No Data Found");
          }
          else {
              
              
                  setMessage(response.data.message);    
          }
        })
        .catch((error) => {
          console.log(error);
  
          

              localStorage.removeItem("Refresh_Token");
              localStorage.removeItem("Token");
              window.location.href = "/";        

  
        });
  }
  );

  function backButton() {
      window.location.href = "/dashboard";
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleKeyPress =(e)=>{
    console.log(e)
    if(e.key === 'Enter'){
      
      submitSearch();
    }

  }

// patient report download modal


const [openView, setOpenView] = React.useState(false);

const handleClickOpenView = (data) =>{
  console.log(data.id)
    
    setOpenView(true);
    setPatientID(data.pd_id)
    let patientId = data.pd_id
    getPatientReport(patientId)

    // if(patientId){
    //   getPatientReport()
    // }else {

    // }
   
  //  getPatientReport(patientId)
    
}

// Edit Button Start

const [openEdit, setOpenEdit] = React.useState(false);
const [patientInfo, setPatientInfo] = React.useState("");


const handleClickEdit = (data) => {
  console.log(data);
  getProgramList()
 setPatientInfo(data);
 setOpenEdit(true);
};


const handleCloseEdit = () => {
  setOpenEdit(false);
}; 


// Edit Button End


const handleCloseView = () => {
// setPatientID("")
setPatientID("")
setPatientReport("")
setTotalReportPages(1)
setOpenView(false);
};
// patient report download

const getPatientReport = useCallback(
async (id,{
    
    table_config_rows_per_page = "15",
    table_config_page_no = "1",
    
   
} = {}) => {
    setCurrentPage(parseInt(table_config_page_no));

    console.log(patientID)

    setPatientID(id)

    let data = {
      
        table_config_rows_per_page: table_config_rows_per_page,
        table_config_page_no: table_config_page_no,
        pd_id: id ? id : patientID
    };

    setTableMessage("Loading...");

    apiAxios
        .post("/report/patient-history-report", data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("Token"),
            },
        })
        .then((response) => {
            console.log("patient list data --> ", response);
            if (response.data.statusCode == 200) {
                setPatientReport(response.data.data);
                setTotalReportPages(response.data.total_pages);

                if (response.data.data.length == 0) {
                    setTableMessage("No Data Found");
                }
            } else {
                toast.error(response.data.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .catch((error) => {
            console.log(error);

            toast.error("Something Went Wrong!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
}
);


function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;


}


 
    return (
        <div>
             <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
 
             <Header />
             <Box className={classes.pagecontent}>
                 <Box className={classes.pageTop}>
                     <Button onClick={backButton} className={classes.Backbtn}><BiArrowBack className={classes.backarrow} /> Back to previous</Button>
                 </Box>
               

             
                 <Box className={classes.Searchresult}>
                     <Box className={classes.Topfilter}>
   
                 <Box className={classes.FilterCol}>
                 <label>Provider Filter</label>
                      <Select options={providerListOption} value={selectedProvider} onChange={setSelectedProvider} placeholder="Select Provider" className={classes.select} style={{width:'100%'}} />
                 </Box>
                 <Box className={classes.FilterCol2}>
                   <label>Program Filter</label>
                      <Select options={programListOption} value={selectedProgram} onChange={setSelectedProgram} placeholder="Select Program" className={classes.select} style={{width:'100%'}} />
                      </Box>
                 
                   <Box className={classes.FilterSearchCol}>
                  <label>Patient Search</label>
                  <TextField className={classes.input} onChange={(e) => setSearchVal(e.target.value)} onKeyPress={handleKeyPress} placeholder="Patient Search" type="text" />
                  </Box>
                  <Button className={classes.Searchbtn} onClick={submitSearch}>Search</Button>
                  <FormGroup style={{margin:'30px 0 0 15px',}}>
      <FormControlLabel control={<Checkbox checked={checked} onChange={handleChangeChecked} />} label="Assign to me only" />
    </FormGroup>
                  <p style={{color: 'blue', cursor: 'pointer', margin:'40px 0 0 0',cursor:'pointer'}} onClick={resetFilter}>Reset</p>  
                 
                 
                </Box>
                     <Box>
                         {(searchVal && flag) ?
                            <h4>Search Results for ‘{searchVal}’</h4>
                            :
                            <></>
                         }
                    </Box>
                    <Box className={classes.PatientListTable}>  
                     <Box className={classes.throw}>
                        <Box className={classes.provider}>Provider</Box>  
                         <Box className={classes.firstname}>First Name</Box>
                         <Box className={classes.lastname}>Last Name</Box>
                         <Box className={classes.dob}>DOB</Box>
                         <Box className={classes.hrpmID}>HRPM ID</Box>
                         <Box className={classes.emrID}>EMR ID</Box>
                         <Box className={classes.status}>Status</Box>
                         <Box className={classes.phone}>Phone</Box>
                         <Box className={classes.program}>Program</Box>
                         <Box className={classes.date}>Date Loaded</Box>
                         <Box className={classes.agentID}>Agent Name</Box>
                         <Box className={classes.finaldate}>Finalize Date</Box>
                         <Box className={classes.plan}>Care Plan</Box>
                         <Box className={classes.actionHead}>Action</Box>
                     </Box>
                     {(patientList.length > 0) ?
                    <>
                    {patientList.map((each) => (
                     <Box className={classes.tdrow}>
                         <Box className={classes.provider}>{each.provider_first_name + " " + each.provider_last_name}</Box>  
                         <Box className={classes.firstname}>{each.patient_first_name}</Box>
                         <Box className={classes.lastname}>{each.patient_last_name}</Box>
                         <Box className={classes.dob}>{each.patient_dob}</Box>
                         
                         <Box className={classes.hrpmID}>{each.hrpm_id}</Box>
                         <Box className={classes.emrID}>{each.emr_id}</Box>
                         <Box className={classes.status}>{each.status == "2" ? "Opted-Out" : (each.status == "1" ? "Active": "Inactive" )}</Box>
                         <Box className={classes.phone}>{formatPhoneNumber(each.patient_primary_phone)}</Box>
                         {each.status == "1" ? (<Box className={classes.program} onClick={()=>openPatProgram(each)}><b style={{color: '#88b3eb'}}>{each.program_abbrev}</b></Box>) 
                         : 
                         (<Box className={classes.program}><b style={{color: '#88b3eb'}}>{each.program_abbrev}</b></Box>)}
                         
                         <Box className={classes.date}>{each.patient_load_date? each.patient_load_date : "--"}</Box>
                         <Box className={classes.agentID}>{each.agent_id}</Box>
                         <Box className={classes.finaldate}>{each.finalize_date? moment.utc(each.finalize_date).local().format("YYYY-MM-DD HH:mm a") : "--"}</Box>
                         { each.finalized_id ?
                         <Box className={classes.plan}><Button className={classes.planbtn} onClick={() => downloadPDF(each.finalized_id)}><b>Download PDF</b></Button></Box>
                         :
                         <Box className={classes.plan}><Button className={classes.planbtn} style={{opacity: 0.3, pointerEvents: 'none'}}><b>Download PDF</b></Button></Box>
                         }
                          <Box className={classes.action}>
                            <Button className={classes.planbtn} onClick={() => deleteModal(each.cpatp_id)}><b>Delete</b></Button>
                          <Button className={classes.planbtn} style={{marginTop: "2px"}} onClick={()=>{handleClickOpenView(each)}}><b>Report</b></Button>
                          <Button className={classes.planbtn} style={{marginTop: "2px"}} onClick={()=>{handleClickEdit(each)}}><b>Edit</b></Button>
                          </Box>
                     </Box>
                    ))}
                    </>
                     :
                     <Box style={{ marginTop:'30px',textAlign: 'center'}}>
                        {message}
                     </Box>
                     }
                     </Box>    
                    </Box>
                
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'right',
                        justifyContent: 'right',
                      }}
                    >
                    <Pagination
                        count= {totalPages}
                        color="primary"
                        page={currentPage}
                        onChange={(event, pageNumber) =>
                          getPatientList({
                            table_config_page_no: `${pageNumber}`,
                          })
                        }
                      /> 
                </div> 
                
             </Box>
            
                       {/* Excel List */}
        <Dialog
        className={classes.downloadModal}
        open={openView}
        disableEnforceFocus={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseView}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.ccmmodal}>
          <Box className={classes.ModalTop}>
          <h3 className={classes.TopHeading}>Patient Report List</h3>
            {/* <Typography variant="h5" className={classes.TopHeading}>
              Excel List 
            </Typography> */}
            <Button onClick={handleCloseView} className={classes.closebtn}>
              <BiXCircle size='20' className={classes.closeicon} />
            </Button>
          </Box>
          
          <DialogContentText id="alert-dialog-slide-description">
        <Grid container spacing={3}>
        
        <Grid item xs={12}>
          
          <Box className={classes.Throw1}>
            <Box className={classes.Thcol5}>Patient Name</Box>
            <Box className={classes.Thcol6}>Provider Name</Box>
            <Box className={classes.Thcol8}>Finalized Date</Box>
            <Box className={classes.Thcol11}>Finalized Total Time</Box>
            <Box className={classes.Thcol12}>Download</Box>
           
          </Box>

          {patientReport.length > 0 ? (
            <>
              {patientReport.map((each) => (
                <Box className={classes.Tdrow1}>
                  <Box className={classes.Tdcol5}>{each.patient_name}</Box>
                  <Box className={classes.Tdcol6}>{each.provider_name}</Box>
                  <Box className={classes.Tdcol8}>
                  {each.finalized_datetime? moment(each.finalized_datetime).format("YYYY-MM-DD HH:mm a") : "--"}
                  </Box>
                  <Box className={classes.Tdcol11}>
                  {each.finalized_total_minutes? moment(each.finalized_total_minutes).format("HH:mm") : "--"}
                  </Box>
                 
                  <Box className={classes.Tdcol12}>
                    {/* <Button className={classes.EditBtn} onClick={() => handleClickOpenView(each,2)}>Edit</Button> */}
                    <Button
                      className={classes.ActionBtn}
                        onClick={()=>{
                            window.location =domain_name+ each.s3_finalization_pdf_url
                        }}
                    >
                     Download
                    </Button>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Box className={classes.Tdrow1}>
              <Box
                className={classes.Tdcol12}
                style={{ width: "100%", textAlign: "center", color: "#dfdfdf" }}
              >
                No Data Available
              </Box>
            </Box>
          )}
            
          <br /> 

          <Pagination
            count={totalReportPages}
            color="primary"
            page={currentReportPage}
            onChange={(event, pageNumber) =>
              getPatientReport(patientID,{
                table_config_page_no: `${pageNumber}`,
              })
            }
          />

         
        </Grid>
      </Grid>

                
           
          
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.modalbtn}>
          <FormGroup aria-label="position" row></FormGroup>
        
        </DialogActions>
      </Dialog>

      <EditPatientProgramModal
              isOpen={openEdit}
              onClose={handleCloseEdit}
              providerList={providerListOption}
              programList={programListOption}
              patientDetails={patientInfo}
            ></EditPatientProgramModal>
        </div>
    )
}

export default PatientSearch
const useStyles = makeStyles(() => ({
    pagecontent:{
        width:'94%',
        background: 'linear-gradient(to bottom, #0f3d6e05, #ffffff00)',
        padding:'20px 3%',
        display:'flex',
        flexDirection:'column',
        '@media only screen and (max-width: 1200px)':{
          width:'96%',
          padding:'20px 2%',
                },
        '& a':{
          textDecoration:'none'
        }
    },
    TopHeading:{
      marginTop:0
    },
    ModalTop:{
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between'
    },
    PatientListTable:{
      width:'100%',
      '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
       overflowX:'auto'
              }
    },
    chartbtn:{
      background:'#0f3d6e',
      borderRadius:'10px',
      padding:'0 20px',
      height:'40px',
      color:'#fff',
      textTransform:'capitalize',
      fontSize:'14px',
      cursor:'pointer',
      '&:hover':{
          background:'#333'
      }
  },
  loginbtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'120px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    textTransform:'capitalize',
    '&:hover':{
        background:'#333'
    }
  },
  ActionBtn: {
    borderRadius: 10,
    textTransform: "capitalize",
    background: "#0A70E8",
    color: "#fff",
    "&:hover": {
      background: "rgba(0,0,0,0.8)",
    },
  },
  modalNoBtn:{
    background:'#0f3d6e',
    padding:'0 40px',
    width:'120px',
    height:'40px',
    borderRadius:'10px',
    color:'#fff',
    marginTop:'20px',
    textTransform:'capitalize',
    '&:hover':{
        background:'#333'
    }
  },
    throw:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'2px #E3E5E5 solid',
        fontSize:'14px',
        color:'#fff',
        paddingBottom:'5px',
        background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
        padding: 6,
        
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
         width:1400,
                }
    },
    tdrow:{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px #E3E5E5 solid',
        fontSize:'14px',
        padding:'10px 6px',
       
        '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
          width:1400,
                 }
    },
    providerbtn:{
        display:'flex',
        cursor:'pointer',
        '& span':{
            display:'flex',
            flexDirection:'column',
            width:'20px',
            marginRight:'10px',
            '& button':{
                background:'none',
                border:'none',
                height:'10px',
                cursor:'pointer'
            }
        }
    },
    provider:{
        width:'9.5%',
        textAlign:'left',
        paddingRight:'0.5%',
        fontSize:14,
    },
    firstname:{
        width:'9.5%',
        textAlign:'left',
        wordWrap:"break-word",
        overflow: "auto",
        paddingRight:'0.5%',
        fontSize:14,
        
    },
    lastname:{
        width:'9.5%',
        textAlign:'left',
        wordWrap:"break-word",
        overflow: "auto",
        paddingRight:'0.5%',
        fontSize:14,
    },
    dob:{
        width:'9.5%',
        textAlign:'left',
        paddingRight:'0.5%',
        fontSize:14,
    },
    // emrID:{
    //     width:'5%',
    //     textAlign:'left'
    // },
    hrpmID:{
        width:'5.5%',
        textAlign:'left',
        paddingRight:'0.5%',
        fontSize:14,
    },
    emrID:{
      width:'5.5%',
      textAlign:'left',
      wordWrap: "break-word",
      paddingRight:'0.5%',
      fontSize:14,
  },
  status:{
    width:'5.5%',
    // textAlign:'center'
    paddingRight:'0.5%',
    fontSize:14,
},
    phone:{
        width:'9.5%',
        textAlign:'left',
        paddingRight:'0.5%',
        fontSize:14,
    },
    program:{
        width:'9.5%',
        textAlign:'left',
        cursor: 'pointer',
        paddingRight:'0.5%',
        fontSize:14,
    },
    date:{
        width:'9.5%',
        textAlign:'center',
        paddingRight:'0.5%',
        fontSize:14,
    },
    agentID:{
        width:'9.5%',
        textAlign:'left',
        paddingRight:'0.5%',
        fontSize:14,
    },
    finaldate:{
        width:'9.5%',
        textAlign:'left',
        paddingRight:'0.5%',
        fontSize:14,
    },
    plan:{
        width:'12.5%',
        textAlign:'left',
        paddingRight:'0.5%',
        fontSize:14,
    },
    actionHead:{
      width:'5.5%',
      textAlign:'right',
      paddingRight:'0.5%',
      fontSize:14,
  },
  action:{
    width:'5.5%',
    textAlign:'right',
    paddingRight:'0.5%',
    fontSize:14,
},
    planbtn:{
        fontSize:'12px',
        color:'#fff',
        background: '#0f3d6e',
        textTransform:'capitalize',
        padding:'0px 5px',
        '&:hover':{
          background: '#333',
        }
    },
    pageTop:{
        textAlign:'left',
        '& button':{
            padding:'0px 5px',
            background:'none',
            color:'#bfc4cb',
            fontSize:'14px',
            textTransform:'capitalize',
            fontWeight:'500',
            borderRadius:20,
        }
    },
    backarrow:{
        color:'#b4bcc9',
        fontSize:'20px',
        marginRight:'8px',
    },
    Searchresult:{
        '& h4':{
            fontSize:'16px',
            color:'#0f3d6e',
            fontWeight:'500',
            margin:'40px 0',
            textAlign:'left'
        }
    },
    btncol:{
        display:'flex',
        justifyContent:'space-between',
        marginBottom:40,
   },
  
   FilterCol:{
    width:'15.5%',
    marginRight:15,
    '@media only screen and (max-width: 1200px)':{
      width:'32%',
      },
    '@media only screen and (max-width: 768px)':{
      width:'47.5%',
      },
  },
  FilterCol2:{
    width:'20%',
    marginRight:15,
    '@media only screen and (max-width: 1200px)':{
      width:'32%',
      },
    '@media only screen and (max-width: 768px)':{
      width:'47.5%',
      },
  },
  FilterSearchCol:{
    width:'30%',
    '@media only screen and (max-width: 1200px)':{
      width:'32.5%',
      },
    '@media only screen and (max-width: 768px)':{
      width:'45.5%',
      },
  },
Topfilter:{
    width:'100%',
    display:'flex',
    marginTop:30,
    marginBottom:50,
    flexDirection:'row',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      flexWrap:'wrap',
            },
    '& label':{ color:'#666',marginBottom:5, display:'flex'},
    
    '& .MuiInput-underline:before':{
        display:'none'
    },
    '& .MuiInput-underline:after':{
        display:'none'
    },
    '& .MuiInput-formControl':{ 
        height:'50px',
        
    },
    '& .MuiInput-input:focus':{
        border:'1px #0074D9 solid',
        boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
    },
    '& .MuiInputBase-input':{
        height:'50px',
        borderRadius:'10px',
        border:'1px #D5D5D5 solid',
        backgroundColor:'#F9F9F9',
        padding:'0 15px',
        fontFamily:'poppins'
    },
    '& .MuiTextField-root':{
        width:'100%'
    }
},
Searchbtn:{
    background:'#0f3d6e',
    borderRadius:10,
    display:'flex',
    color:'#fff',
    height:50,
    paddingLeft:30,
    paddingRight:30,
    marginLeft:15,
    textTransform:'capitalize',
    fontFamily:'poppins',
    marginTop:28,
    '@media only screen and (max-width: 820px)':{
      marginLeft:15,
      },
    '@media only screen and (max-width: 768px)':{
      marginLeft:15,
      },
    '&:hover':{
      background:'rgba(0,0,0,0.8)'
    }
  },
  Formcol:{
    display:'flex',
    alignItems:'center',
    marginBottom:'20px',
    '&>div':{
        width:'100%'
    },
    '& p':{
        fontSize:'18px',
        margin:'0px'
    },
    '& label':{
        flex:'0 0 205px',
        color:'#000',
        fontSize:'15px',
        fontWeight:'400'
    },
    '& .react-dropdown-select-input':{
        width:'100%',
        fontFamily:'poppins'
    }
  },
  select:{
    width:'100%',
    border:'none !important',
    borderRadius:'10px !important',
    border:'1px #D5D5D5 solid',
    backgroundColor:'#F9F9F9',
    display:'flex',
    justifyContent:'flex-start',
    textAlign:'left',
    height:'50px',
    fontSize:'14px !important',
    paddingLeft:'15px !important',
    paddingRight:'15px !important',
    // '& .react-dropdown-select-input':{
    //   display:'none'
    // }
  },
  modal:{
    '& .MuiPaper-rounded':{
        borderRadius:'10px !important',
        padding:'20px',
        width:'550px',
        maxWidth:'550px'
    }
  },
  downloadModal: {
    "& .MuiPaper-rounded": {
        borderRadius: "10px !important",
        padding: "20px",
        width: "950px",
        maxWidth: "950px",
        
    },
},
  ccmmodal:{
    borderRadius:'10px',
      '& .MuiInput-underline:before':{
          display:'none'
      },
      '& .MuiInput-underline:after':{
          display:'none'
      },
      '& .MuiInput-formControl':{ 
          height:'50px',
          
      },
      '& .MuiInput-input:focus':{
          border:'1px #0074D9 solid',
          boxShadow:'2px 2px 10px 1px rgba(0,0,0,0.3)'
      },
      '& .MuiInputBase-input':{
          height:'50px',
          borderRadius:'10px',
          border:'1px #D5D5D5 solid',
          backgroundColor:'#F9F9F9',
          padding:'0 15px'
      },
      '& .react-date-picker__wrapper':{
        borderRadius:10,
        border:'1px #D5D5D5 solid',
        backgroundColor:'#F9F9F9',
        padding:'0 10px',
    },
  },
  modalbtn:{
    display:'flex',
    justifyContent:'space-between',
    marginRight:'30px',
    marginLeft:'15px',
    alignItems:'center'
  },
  leftnav:{
    position:'absolute',
    top:'15px',
    bottom:'15px',
    left:'40px',
    right:'40px'
    },
    Leftcol: {
      width: "12%",
      padding: "20px 3%",
      position: "relative",
      minHeight: "800px",
      background: 'linear-gradient(45deg, #114ebd, #0f3d6e)',
    },
    Rightcol:{
      width:'78%',
      padding:'20px 2%',
      borderLeft:'1px #F6F6F6 solid',
      '& .MuiAccordionSummary-root':{
          borderBottom:'1px #5f80a9 solid',
          height:'40px',
          color:'#bfc4cb',
          padding:'0px',
          fontFamily:'Poppins',
      },
      '& .MuiAccordion-root:before':{
          display:'none'
      },
      '& .MuiTab-root':{
        minWidth:'33%',
      },
      '& .MuiTab-root:nth-child(6)':{
        minWidth:'30%',
      },
      '& .MuiTab-root:nth-child(7)':{
        minWidth:'30%',
      },
      '& .MuiBox-root':{
          paddingLeft:'0px',
          paddingRight:'0px'
      }
  },
  
Throw1: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color:'#fff',
  borderBottom: "1px rgba(0,0,0,0.1) solid",
  padding: 6,
  background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
  fontSize:'14px',
},
Thcol: {
  width: "8%",
  color:'#fff',
},
Thcol2: {
  width: "8%",
  color:'#fff',
},
Thcol3: {
  width: "8%",
  color:'#fff',
},
Thcol4: {
  width: "8%",
  color:'#fff',
},

Thcol5: {
  width: "22%",
  fontSize: 14,
  color: "#696969",
  color:'#fff',
},
Thcol6: {
  width: "23%",
  fontSize: 14,
  color:'#fff',
},

Thcol8: {
  width: "19%",
  fontSize: 14,
  color:'#fff',
},
Thcol9: {
  width: "14%",
  fontSize: 14,
  color: "#696969",
},
Thcol10: {
  width: "20%",
  fontSize: 14,
  color: "#696969",
},
Thcol11: {
  width: "20%",
  fontSize: 14,
  color:'#fff',
  // display: "flex",
  // justifyContent: "flex-end",
},

Thcol7: {
  width: "10%",
},

Thcol12: {
  width: "15%",
  fontSize: 14,
  color:'#fff',
  textAlign:'right'
},
Thcol13: {
  width: "10%",
},
Tdrow: {
  display: "flex",
  alignItems: "center",
  borderBottom: "1px #ccc solid",
  padding: "10px 0",
  fontSize: 14,

},
Tdrow1: {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: 15,
  borderBottom: "1px rgba(0,0,0,0.1) solid",
  padding: "10px 0",
},
Tdcol5: {
  width: "21.5%",
  wordWrap:"break-word",
  overflow: "auto",
  paddingRight:'0.5%',
  fontSize:14,
},
closebtn: {
  width: "40px",
  position: "absolute",
  right: "10px",
  height: "40px",
  background: "#fff",
  top: "10px",
  minWidth: "40px",
  "&:hover": {
    background: "#fff",
  },
},
Tdcol6: {
  width: "22.5%",
  paddingRight:'0.5%',
  wordWrap:"break-word",
  overflow: "auto",
  fontSize:14,
},
Tdcol7: {
  width: "13.5%",
  paddingRight:'0.5%',
  fontSize:14,
},
Tdcol8: {
  width: "20.5%",
  paddingRight:'0.5%',
  wordWrap:"break-word",
  overflow: "auto",
  fontSize:14,
},
Tdcol9: {
  width: "13.5%",
  paddingRight:'0.5%',
  fontSize:14,
},
Tdcol10: {
  width: "19.5%",
  paddingRight:'0.5%',
  fontSize:14,
},
Tdcol11: {
  width: "19.5%",
  paddingRight:'0.5%',
  fontSize:14,
  // display: "flex",
  // justifyContent: "flex-end",
},
Tdcol12: {
  width: "15%",
  textAlign:'right',
  fontSize:14,
},
}));