import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import calendericon from "../images/calendar-icon.svg";
import moment from "moment";
import { apiAxios } from "../Util/ApiAxios";
import { Pagination } from "@material-ui/lab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Finalize({ options }) {
  const classes = useStyles();
  let pat_details = JSON.parse(localStorage.getItem("patient"));
  const [finalizeData, setFinalizeData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (finalizeData.length == 0) {
      getFinalize("", "", 1);
    }
  }, []);

  const getFinalize = (ascVal, dscVal, pageNum) => {
    if (pageNum && !(currentPage == pageNum)) {
      setCurrentPage(pageNum);
    }

    let data = {
      table_config_rows_per_page: "15",
      table_config_page_no: pageNum ? pageNum : "1",
      start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
      end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
      sort_by_desc: dscVal,
      sort_by_asc: ascVal,
    };

    apiAxios
      .post("/report/list-finalize-reports", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("Token"),
        },
      })
      .then((response) => {
        if (response.data.statusCode == 200) {
          console.log("Finalize report data", response.data);
          setFinalizeData(response.data.data);
          setTotalPages(response.data.total_pages);
          // window.location.href = "/patient-search"; 
        } else {
       
        }
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error.message, {
        //   position: "bottom-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      });
  };

  const ascClick = (e, param) => {
    e.preventDefault();
    getFinalize(param ? param : "", "", currentPage);
  };

  const dscClick = (e, param) => {
    e.preventDefault();
    getFinalize("", param ? param : "", currentPage);
  };

  const onPageChange = (e, param) => {
    e.preventDefault();
    if (!(currentPage == param)) {
      setCurrentPage(param);
      getFinalize("", "", param);
    }
  };

  return (
    <div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Box className={classes.loginform}>
        <form>
          <Box className={classes.Formcol}>
            <label>Select Date</label>
            <Box className={classes.startDate}>
              <Button className={classes.calendaricon}>
                {/* <img src={calendericon} alt="calendar icon" /> */}
              </Button>
              <DatePicker
                // className={classes.input}
                placeholder="Staet Date"
                value={startDate}
                clearIcon={null}
                onChange={(date) => setStartDate(date)}
                maxDate={endDate}
              />
            </Box>
            <Box style={{ marginLeft: "20px" }}>-</Box>
            <Box className={classes.Enddate}>
              <Button className={classes.calendaricon}>
                {/* <img src={calendericon} alt="calendar icon" /> */}
              </Button>
              <DatePicker
                // className={classes.input}
                placeholder="End Date"
                clearIcon={null}
                value={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Box>
            <Button
              className={classes.nextbtn}
              onClick={() => {
                getFinalize("", "", 1);
              }}
            >
              Generate
            </Button>
          </Box>
          <Box className={classes.Careplan}>
            <Box className={classes.throw}>
              <Box className={classes.Row1}>
                <Box className={classes.providerbtn} role="button">
                  {" "}
                  EMR {" "}
                  <span>
                    <button
                      onClick={(e) => {
                        dscClick(e, "emr");
                      }}
                    >
                      <BiChevronUp />
                    </button>
                    <button
                      onClick={(e) => {
                        ascClick(e, "emr");
                      }}
                    >
                      <BiChevronDown />
                    </button>
                  </span>{" "}
                </Box>{" "}
              </Box>
              <Box className={classes.Row2}>
                <Box className={classes.providerbtn} role="button">
                  {" "}
                  HRPM {" "}
                  <span>
                    {/* <button
                      onClick={(e) => {
                        dscClick(e, "hrpm");
                      }}
                    >
                      <BiChevronUp />
                    </button> */}
                    {/* <button
                      onClick={(e) => {
                        ascClick(e, "hrpm");
                      }}
                    >
                      <BiChevronDown />
                    </button> */}
                  </span>{" "}
                </Box>
              </Box>
              <Box className={classes.Row3}>
                <Box className={classes.providerbtn} role="button">
                  {" "}
                  Program{" "}
                  <span>
                    <button
                      onClick={(e) => {
                        dscClick(e, "program");
                      }}
                    >
                      <BiChevronUp />
                    </button>
                    <button
                      onClick={(e) => {
                        ascClick(e, "program");
                      }}
                    >
                      <BiChevronDown />
                    </button>
                  </span>{" "}
                </Box>
              </Box>
              <Box className={classes.Row4}>
                <Box className={classes.providerbtn} role="button">
                  {" "}
                  User Name{" "}
                  <span>
                    <button
                      onClick={(e) => {
                        dscClick(e, "user_id");
                      }}
                    >
                      <BiChevronUp />
                    </button>
                    <button
                      onClick={(e) => {
                        ascClick(e, "user_id");
                      }}
                    >
                      <BiChevronDown />
                    </button>
                  </span>{" "}
                </Box>
              </Box>
              <Box className={classes.Row5}>
                <Box className={classes.providerbtn} role="button">
                  {" "}
                  Elapsed Time
                </Box>
              </Box>
              <Box className={classes.Row6}>
                <Box className={classes.providerbtn} role="button">
                  {" "}
                  Engagement <br />
                  Completion Date{" "}
                  <span>
                    <button
                      onClick={(e) => {
                        dscClick(e, "engagement_date");
                      }}
                    >
                      <BiChevronUp />
                    </button>
                    <button
                      onClick={(e) => {
                        ascClick(e, "engagement_date");
                      }}
                    >
                      <BiChevronDown />
                    </button>
                  </span>{" "}
                </Box>
              </Box>
              <Box className={classes.Row7}>
                <Box className={classes.providerbtn} role="button">
                  {" "}
                  Status
                </Box>
              </Box>
              <Box className={classes.Row8}>Billing Code</Box>
            </Box>
            {finalizeData.length > 0 ? (
              finalizeData.map((item) => (
                <>
                  <Box className={classes.tdrow}>
                    <Box className={classes.Row1}>{item.emr_id}</Box>
                    <Box className={classes.Row2}>{item.hrpm_id}</Box>
                    <Box className={classes.Row3}>{item.program_abbrev}</Box>
                    <Box className={classes.Row4}>{item.user_name}</Box>
                    <Box className={classes.Row5}>{item.total_time}</Box>
                    <Box className={classes.Row6}>
                      {item.engagement_completion_datetime? moment.utc(item.engagement_completion_datetime).local().format(
                        "YYYY-MM-DD"
                      ) : ""}
                    </Box>
                    <Box className={classes.Row7}>
                      {item.finalize_flag == "0" ? "Unfinalized" : "Finalized"}
                    </Box>
                    <Box className={classes.Row8}>
                      {item.primary_billing_code}
                    </Box>
                  </Box>
                </>
              ))
            ) : (
              <div
                style={{
                  textAlign: "center",
                  margin: "5%",
                  width: "100%",
                  color: "#0f3d6e",
                }}
              >
                No Data Found
              </div>
            )}
          </Box>
          <Box className={classes.btncol}>
            {/* <Button className={classes.exportbtn}>Export/Print</Button> */}
          </Box>
        </form>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
          }}
        >
          <Pagination
            count={totalPages}
            color="primary"
            page={currentPage}
            onChange={(event, pageNumber) => onPageChange(event, pageNumber)}
          />
        </div>
      </Box>
    </div>
  );
}

export default Finalize;
const useStyles = makeStyles(() => ({
  input: {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "100%",
    background: "#F9F9F9",
    padding: "0 15px",
  },
  exportbtn: {
    fontSize: "18px",
    color: "#88b3eb",
    textTransform: "capitalize",
    "&:hover": {
      color: "#000",
      background: "transparent",
    },
  },
  Formcol: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    "& .react-datepicker-wrapper": {
      width: "145px",
    },
    "& p": {
      fontSize: "18px",
      margin: "0px",
    },
    "& label": {
     marginRight:30,
    },
    "& .react-dropdown-select-input": {
      width: "100%",
    },
  },
  loginform: {
    width: "100%",
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
    "& .MuiInput-formControl": {
      height: "50px",
    },
    "& .MuiInputBase-input": {
      height: "50px",
      borderRadius: "10px",
      background: "#F9F9F9",
      padding: "0 15px",
    },
    "& .react-date-picker__wrapper": {
      borderRadius: 10,
      border: "1px #D5D5D5 solid",
      backgroundColor: "#F9F9F9",
      padding: "0 10px",
      height:50,
    },
    "& .react-date-picker__inputGroup__input:focus": {
      border: 0,
      boxShadow: "none",
    },
  },
  loginbtn: {
    background: "#0f3d6e",
    padding: "0 40px",
    width: "142px",
    height: "45px",
    borderRadius: "10px",
    color: "#fff",
    marginTop: "20px",
    "&:hover": {
      background: "#333",
    },
  },
  throw: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px #E3E5E5 solid",
    alignItems: "center",
    fontSize: "13px",
    lineHeight: "16px",
    color: "#bfc4cb",
    paddingBottom: "5px",
    width: "100%",
    alignItems: "center",
    background: 'linear-gradient(to left, #114ebd, #0f3d6e)',
    color:'#fff',
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1200,
            },
  },
  tdrow: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px #E3E5E5 solid",
    fontSize: "14px",
    padding: "15px 0",
    width: "100%",
    alignItems: "center",
    lineHeight: "15px",
    color: "#000",
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      width:1200,
            },
  },
  calendaricon: {
    position: "absolute",
    right: "-10px",
    zIndex: "1",
    top: "15px",
    minWidth: "15px",
    height: "20px",
    width: "15px",
  },
  checkicon: {
    fontSize: "25px",
    color: "#47C932",
  },
  inputfile: {
    display: "none",
  },
  select: {
    width: "200px",
    border: "none !important",
    borderRadius: "10px !important",
    background: "#F9F9F9",
    height: "50px",
    fontSize: "18px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
  },
  startDate: {
    marginRight:0,
    position: "relative",
  },
  Enddate: {
    position: "relative",
    marginRight: "15px",
    marginLeft: "20px",
  },
  Toptext: {
    fontSize: "18px",
    color: "#0f3d6e",
    fontWeight: "600",
  },
  Textheading: {
    fontSize: "16px",
    marginTop: "0px",
    fontWeight: "500",
  },
  Addbtn: {
    width: "180px",
    height: "45px",
    background: "#E13F66",
    borderRadius: "10px",
    color: "#fff",
    boxShadow: "0px 0px 12px 6px rgba(0, 0, 0, 0.18)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    fontSize: "16px",
    "&:hover": {
      background: "#000",
    },
  },
  cancelbtn: {
    background: "#DADADA",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    "&:hover": {
      background: "#000",
    },
  },
  nextbtn: {
    background: "#0f3d6e",
    borderRadius: "10px",
    textTransform: "capitalize",
    height: "45px",
    width: "120px",
    color: "#fff",
    fontWeight: "600",
    marginLeft:0,
    fontFamily: "Poppins",
    "&:hover": {
      background: "#000",
    },
  },
  btncol: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
  },
  closebtn: {
    width: "40px",
    position: "absolute",
    right: "10px",
    height: "40px",
    background: "#fff",
    top: "10px",
    minWidth: "40px",
    "&:hover": {
      background: "#fff",
    },
  },
  closeicon: {
    fontSize: "25px",
    color: "#0f3d6e",
  },
  Row1: {
    width: "10%",
    wordWrap : "break-word",
    marginLeft : 5,
    fontFamily:'poppins',
    fontSize:14,
  },
  Row2: {
    width: "10%",
    wordWrap : "break-word",
    fontFamily:'poppins',
    fontSize:14,
  },
  Row3: {
    width: "12%",
    wordWrap : "break-word",
    fontFamily:'poppins',
    fontSize:14,
  },
  Row4: {
    width: "13%",
    wordWrap : "break-word",
    fontFamily:'poppins',
    fontSize:14,
  },
  Row5: {
    width: "12%",
    fontFamily:'poppins',
    fontSize:14,
  },
  Row6: {
    width: "15%",
    fontFamily:'poppins',
    fontSize:14,
  },
  Row7: {
    width: "11%",
    display: "flex",
    justifyContent: "center",
    textAlign: "right",
    fontFamily:'poppins',
    fontSize:14,
  },
  Row8: {
    width: "10%",
    // display: "flex",
    // justifyContent: "flex-end",
    textAlign: "center",
    wordWrap : "break-word",
    fontFamily:'poppins',
    fontSize:14,
  },
  Row9: {
    width: "5%",
    textAlign: "right",
    fontFamily:'poppins',
    fontSize:14,
  },
  Row10: {
    width: "10%",
    textAlign: "right",
    fontFamily:'poppins',
    fontSize:14,
  },
  providerbtn: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    "& span": {
      display: "flex",
      flexDirection: "column",
      width: "20px",
      marginRight: "10px",
      "& button": {
        background: "none",
        border: "none",
        height: "10px",
        cursor: "pointer",
        color:'#fff',
      },
    },
  },
  Careplan: {
    width: "100%",
    '@media only screen and (min-device-width: 768px) and (max-device-width: 1200px)':{
      overflowX:'auto'
            },
    "& .MuiAccordionSummary-root": {
      borderBottom: "1px #5f80a9 solid",
      height: "auto",
      color: "#bfc4cb",
      padding: "0px",
      fontFamily: "Poppins",
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      order: "2",
      width: "100%",
    },
    "& .MuiIconButton-root": {
      padding: "5px",
      fontSize: "16px",
      marginRight: "5px",
      color: "#88b3eb",
      position: "absolute",
      left: "-25px",
    },
    "& .MuiAccordion-root:before": {
      display: "none",
    },
  },
}));
